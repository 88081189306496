import React, { ReactElement, useState, useEffect, FormEvent } from 'react';
import logo from './assets/images/logo.png';
import './App.scss';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import Phone_EE from '../src/assets/images/iphone-4OJO.png';
import Phone_EN from '../src/assets/images/iphone-YOJO.png';
import usePageTracking from './components/PageTracking/usePageTracking';

const App: React.FC = (): ReactElement => {
  usePageTracking();
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const switchLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    if (lang === 'et') {
      setPhoneImg(Phone_EE);
    } else {
      setPhoneImg(Phone_EN);
    }
  };

  type PhoneType = typeof Phone_EE | typeof Phone_EN;

  const [PhoneImg, setPhoneImg] = useState<PhoneType>(Phone_EE);
  const [mailSent, setMailSent] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'et':
          setPhoneImg(Phone_EE);
          break;
        case 'en':
          setPhoneImg(Phone_EN);

          break;
        default:
          setPhoneImg(Phone_EE);

          break;
      }
    }, 100);
  }, []);

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    // console.log('campaign is over');
    const myForm = document.getElementById('email-form') as HTMLFormElement;
    const formData = new FormData(myForm);
    // formData.forEach(entries => console.log(entries));
    // console.log(typeof formData);
    axios
      .post('/api/mail/new', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.IsValid === true) {
          setMailSent(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="App">
      <div className="promo-logo">
        <img src={logo} alt="Direct Messenger Logo" />
        <div className="lang-switcher">
          {' '}
          <button
            type="button"
            className="navbar-item"
            onClick={() =>
              getLanguage() === 'et'
                ? switchLanguage('en')
                : switchLanguage('et')
            }
          >
            {getLanguage() === 'et' ? 'EN' : 'ET'}
          </button>
        </div>
      </div>
      <div className="promo-content">
        <div className="divider"></div>

        <div className="columns">
          <div className="column">
            <h2>{t('first.header')}</h2>
            <p className="bold">{t('first.1')}</p>
            <p>{t('first.2')}</p>
            <p>{t('first.3')}</p>
            <div className="columns button-columns">
              <div className="columns buttons">
                {/* <div className="column">
                  <a href="/" className="button">
                    {t('first.btn1')}
                  </a>
                </div> */}
                <div className="column">
                  <a href="#mail-form" className="button">
                    {t('first.btn2')}
                  </a>
                </div>
                <div className="column">
                  <a
                    href="https://messenger.ee/pricelist"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    {t('first.btn3')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column iphone-img">
            <img src={PhoneImg} alt="" />
          </div>
        </div>
        <div className="divider"></div>
        <div className="columns">
          <div className="column">
            <h2>{t('first.keywords')}</h2>
            <p>
              <span className="bold">{t('first.keywords-1-bold')}</span>
              {t('first.keywords-1-content')}
            </p>
            <p>
              <span className="bold">{t('first.keywords-2-bold')}</span>
              {t('first.keywords-2-content')}
            </p>
            <p>
              <span className="bold">{t('first.keywords-3-bold')}</span>
              {t('first.keywords-3-content')}
            </p>
            <p>
              <span className="bold">{t('first.keywords-4-bold')}</span>
              {t('first.keywords-4-content')}
            </p>
          </div>
        </div>
        <div className="divider"></div>

        <div className="columns">
          <div className="column">
            <div className="personal">
              <p>{t('personal.first')}</p>
              <p className="pink">
                {t('personal.second-1')}{' '}
                <span className="underline">{t('personal.second-1-u')}</span>
                {t('personal.second-2')}{' '}
                <span className="underline">{t('personal.second-2-u')}</span>
                {t('personal.second-3')}{' '}
                <span className="underline">{t('personal.second-3-u')}</span>
                {t('personal.second-4')}{' '}
                <span className="underline">{t('personal.second-4-u')}</span>
                {t('personal.second-5')}{' '}
                <span className="underline">{t('personal.second-5-u')}</span>
                {t('personal.second-6')}{' '}
                <span className="underline">{t('personal.second-6-u')}</span>
                {t('personal.second-7')}{' '}
                <span className="underline">{t('personal.second-7-u')}</span>{' '}
              </p>
              <p className="pink">
                {t('personal.third-1')}{' '}
                <span className="underline">{t('personal.third-1-u')}</span>
                {t('personal.third-2')}{' '}
                <span className="underline">{t('personal.third-2-u')}</span>
                {t('personal.third-3')}{' '}
                <span className="underline">{t('personal.third-3-u')}</span>
                {t('personal.third-4')}{' '}
                <span className="underline">{t('personal.third-4-u')}</span>
                {t('personal.third-5')}{' '}
                <span className="underline">{t('personal.third-5-u')}</span>
              </p>
              <p>{t('personal.fourth')}</p>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <h3 className="boxes-header">{t('second.header')}</h3>

        <div className="columns icon-boxes">
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('second.box-1-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('second.box-2-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('second.box-3-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('second.box-4-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('second.box-5-content')}
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="account-list">
          <p>
            {t('account.head1')}
            <span className="bold">{t('account.head2')}</span>
            {t('account.head3')}
            <span className="bold">{t('account.head4')}</span>
            {t('account.head5')}
          </p>
          <div className="columns">
            <div className="column">
              <ul>
                <li>{t('account.list.1')}</li>
                <li>{t('account.list.2')}</li>
                <li>{t('account.list.3')}</li>
                <li>{t('account.list.4')}</li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>{t('account.list.5')}</li>
                <li>{t('account.list.6')}</li>
                <li>{t('account.list.7')}</li>
                <li>{t('account.list.8')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="price-example">
          <p>
            {t('price.1')}
            <span className="bold">{t('price.2')}</span>
            {t('price.3')}
            <span className="bold pink">{t('price.4')}</span>
            {t('price.5')}
            {/* <span className="bold">{t('price.6')}</span> */}
          </p>
          <p>{t('price.vat')}</p>
          <p>
            {t('price.acc1')}
            <a
              href="https://smart.messenger.ee/register"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('price.acc2')}
            </a>
            {t('price.acc3')}
          </p>
        </div>
        <div id="mail-form">
          <form onSubmit={submitForm} id="email-form">
            <div className="field">
              <label className="label">{t('name')}</label>
              <div className="control">
                <input
                  className="input"
                  name="name"
                  type="text"
                  placeholder={t('name_placeholder')}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">{t('message')}</label>
              <div className="control">
                <textarea
                  name="message"
                  className="textarea"
                  placeholder={t('message_placeholder')}
                ></textarea>
              </div>
            </div>
            <div className="field is-grouped">
              <div className="control">
                {mailSent ? (
                  <span className="mail-sent">{t('mail_sent')}</span>
                ) : (
                  <button type="submit" className="button">
                    {t('submit')}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default App;
